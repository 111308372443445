export const theme = {
  palette: {
    primary: {
      main: '#2196f3',
      light: '#4dabf5',
      dark: '#1769aa',
    },
    secondary: {
      main: '#ff1744',
      light: '#ff459',
      dark: '#b2102f',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: '"Lato", "Avenir Next", "Avenir", "Verdana", "Noto Sans Japanese", sans-serif',
    button: {
      fontSize: 14,
    },
  },
  mixins: {
    toolbar: {
      //height: 50,
      //minHeight: 50,
      backgroundColor: '#333333',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 500,
      }
    },
    MuiChip: {
      root: {
        fontWeight: 500,
      }
    }
  }
};