export function scrollToTop(duration: number) {
  const INTERVAL = 15;
  const scrollStep = -window.scrollY / (duration / INTERVAL);
  const scrollInterval = setInterval(() => {
    if (window.scrollY != 0) {
      window.scrollBy(0, scrollStep);
    } else {
      clearInterval(scrollInterval);
    }
  }, INTERVAL);
}

export function scrollToTarget(targetId: string) {
  const obj = document.getElementById(targetId);
  if (!obj) return;

  obj.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest',
  });
}