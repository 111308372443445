import { Record } from 'immutable';
import { makeRequest } from '../utils';

import isEmpty from 'lodash/isEmpty';

type Props = {
  params?: Object,
  id?: number
  errors: Object,
}

type setContactProps = {
  name: string,
  email: string,
  birthYear: number | string,
  birthMonth: number | string,
  birthDay: number | string,
  gender: string,
  message: string,
}

const WordpressRecord = Record<Props>({
  errors: {},
});

const base = process.env.WORDPRESS_URL;

export default class Wordpress extends WordpressRecord {
  setContact({
    name, email, birthYear, birthMonth,
    birthDay, gender, message
  }: setContactProps) {
    return new Promise(async (resolve, reject) => {
      try {
        const params = {
          name, email, birthYear, birthMonth, 
          birthDay, gender, message
        };

        const response = await makeRequest({ url: base + '/api/app/v1/contacts', method: 'POST', data: params});
      } catch (error) {
        reject(error);
      }
    });
  }
}