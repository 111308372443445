import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import rootReducer from '../reducers';
import thunkMiddleware from 'redux-thunk';

//export const validateMiddleware = ({ dispatch }) => next => action => {
//  if (Example.isValid(dispatch, action)) return;
//  return next(action);
//};

let composeEnhancers = compose;
const middlewares = [thunkMiddleware];

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares)
);

const store = createStore(
  combineReducers({ rootReducer }),
  enhancer,
);

export default store;