import request from 'axios';
import uuid from 'uuid/v4';

type ArgsType = {
  url: string,
  method: string,
  data: Object | null,
  headers?: Object,
};

export default function makeRequest({ url, method, data, headers }: ArgsType = {}) {
  return new Promise(async (resolve, reject) => {
    const requestId = uuid();
    let response: Object;
    let status: number = 500;

    const defaultHeaders = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-Request-Id': requestId,
      'X-CSRF-TOKEN': window['csrf-token'].content,
    };

    try {
      response = await request({ url, method, data, headers: Object.assign(defaultHeaders, headers) });
      if (response) status = response.status;

      if (status >= 200 && status < 300) {
        return resolve(response);
      } else {
        return reject(response); // 必要？
      }
    } catch (error) {
      response = error.response;

      if (response) {
        status = response.status;

        if (response.data.errorMsg && response.data.requestId) {
          response.data.errorMsg = response.data.errorMsg + '\n\nリクエストID: ' + response.data.requestId;
        }
      }

      return reject(response);
    }
  });
}