import { Record } from 'immutable';

type Props = {
  isProcessing: boolean,
  errors: Object,
}

const MasterRecord = Record<Props>({
  isProcessing: false,
  errors: {},
});

export default class Master extends MasterRecord {}